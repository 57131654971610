import { Component } from 'react';

class ChangeCellphoneHandler extends Component {

  componentDidMount() {
    alert('휴대전화번호가 변경되었습니다.');
    window.location.replace('/club-artisee/my-page/');
  }

  render() {
    return null;
  }
}

export default ChangeCellphoneHandler;